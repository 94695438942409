import React from "react";
import "./homepage.scss";

//images
import logo from "../../static/images/logo.svg";
import moblogo from "../../static/images/moblogo.svg";
import raha from "../../static/images/raha.svg";
import ip from "../../static/images/ip.svg";
import happi from "../../static/images/happi.svg";
import amma from "../../static/images/amma.svg";
import vitality from "../../static/images/vitality.svg";
import oxygen from "../../static/images/oxygen.svg";
import hospitals from "../../static/images/hospitals.svg";
import dopamine from "../../static/images/dopamine.svg";

const HomePage = () => {
  const appData = [
    {
      id: 0,
      icon: raha,
      name: "Raha",
    },
    {
      id: 1,
      icon: ip,
      name: "InsuranePortals",
    },
    {
      id: 2,
      icon: happi,
      name: "Happi",
    },
    {
      id: 3,
      icon: amma,
      name: "Amma",
    },
    {
      id: 4,
      icon: vitality,
      name: "Vitality",
    },
    {
      id: 5,
      icon: oxygen,
      name: "Oxygen",
    },
    {
      id: 6,
      icon: hospitals,
      name: "Hospitals",
    },
    {
      id: 7,
      icon: dopamine,
      name: "Dopamine",
    },
  ];

  return (
    <div className="homepage">
      <div className="top-area">
        <div className="top-area-logo">
          <img src={logo} alt="" />
        </div>
      </div>
      <div className="top-area-mob">
        <div className="top-area-mob-logo">
          <img src={moblogo} alt="" />
        </div>
      </div>
      <div className="apps-container">
        {appData.map((e, i) => {
          return (
            <div className="each-app" key={e.id}>
              <div className="app-box">
                <img src={e.icon} alt="" />
              </div>
              <div className="app-name">{e.name}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HomePage;
