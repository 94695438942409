import React, { useState } from "react";
import classNames from "./custom.module.scss";

//assets
import { IoEye, IoEyeOff } from "react-icons/io5";

const Custom = () => {
  return <div>Custom</div>;
};

export default Custom;

export const CustomInput = ({
  title,
  name,
  stateValue,
  setState,
  type,
  placeholder,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={classNames.customInput}>
      {title && <div className={classNames.title}>{title}</div>}
      <div className={classNames.inputContainer}>
        <input
          type={type === "password" && !showPassword ? "password" : "text"}
          value={name ? stateValue[name] : stateValue}
          placeholder={placeholder ? placeholder : "Enter..."}
          onChange={(event) => {
            if (name) {
              setState((prev) => {
                return { ...prev, [name]: event?.target?.value };
              });
            } else {
              setState(event?.target?.value);
            }
          }}
        />
        {type === "password" && (
          <>
            {showPassword ? (
              <IoEyeOff
                onClick={() => {
                  setShowPassword((prev) => !prev);
                }}
              />
            ) : (
              <IoEye
                onClick={() => {
                  setShowPassword((prev) => !prev);
                }}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};
