import React, { useState } from "react";
import classNames from "./navbar.module.scss";
import { useLocation, useNavigate } from "react-router-dom";

//assets
import fullLogo from "../../asset/images/logo.svg";
import { FaCaretDown } from "react-icons/fa";

const Navbar = () => {
  // default
  const navigate = useNavigate();
  const location = useLocation();

  //constant
  const navigations = [
    { title: "For Companies", path: "companies" },
    { title: "For Brokerages", path: "brokerages" },
    { title: "For Agents", path: "agents" },
  ];

  //values
  const [selectedNav, setSelectedNav] = useState("companies");

  return (
    <div className={classNames.navbar}>
      <div className={classNames.logo}>
        <img src={fullLogo} alt="fullLogo" />
      </div>
      <div className={classNames.navigations}>
        {navigations?.map((eachNav, index) => {
          return (
            <div
              className={`${classNames.eachNav} ${
                location?.pathname?.includes(eachNav?.path) ||
                (location?.pathname === "/" && eachNav?.path === "companies")
                  ? classNames.selectedNav
                  : ""
              }`}
              key={eachNav?.title + index}
              onClick={() => {
                setSelectedNav(eachNav?.path);
                navigate(`/${eachNav?.path}`);
              }}
            >
              <span>{eachNav?.title}</span>
              <FaCaretDown />
            </div>
          );
        })}
      </div>
      <div className={classNames.submitBtn}>Request Demo</div>
    </div>
  );
};

export default Navbar;
