import React, { useState } from "react";
import classNames from "./login.module.scss";

//assets
import fullLogo from "../../asset/images/logo.svg";
import { CustomInput } from "../../component/Custom";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();

  //values
  const [loginCredentials, setLoginCredentials] = useState({});

  return (
    <div className={classNames.login}>
      <div className={classNames.loginNav}>
        <img src={fullLogo} alt="fullLogo" onClick={() => navigate("/")} />
        <div className={classNames.submitBtn}>Sign up</div>
      </div>
      <div className={classNames.loginContainer}>
        <div className={classNames.title}>Sign in to CoverFi</div>
        <div className={classNames.inputContainer}>
          <CustomInput
            name="username"
            stateValue={loginCredentials}
            setState={setLoginCredentials}
            placeholder="Email"
          />
          <CustomInput
            name="password"
            stateValue={loginCredentials}
            setState={setLoginCredentials}
            type="password"
            placeholder="Password"
          />
          <div className={classNames.forgetPassword}>Forgot Password</div>
          <div className={classNames.submitBtn}>Login</div>
        </div>
      </div>
    </div>
  );
};

export default Login;
