import React from "react";
import classNames from "./home.module.scss";

//imports
import Navbar from "../../component/navbar";
import { ReactComponent as IndianFlag } from "../../asset/icons/indianflag.svg";

//assest
import couple from "../../asset/images/landing/couple.svg";
import family4 from "../../asset/images/landing/family4.svg";
import family3 from "../../asset/images/landing/family3.svg";
import women from "../../asset/images/landing/women.svg";
import singh from "../../asset/images/landing/singh.svg";
import family4_2 from "../../asset/images/landing/family4_2.svg";
import lawyer from "../../asset/images/landing/lawyer.svg";

const Home = () => {
  return (
    <div className={classNames.home}>
      <Navbar />
      <div className={classNames.contentContainer}>
        <h1>Wellness & insurance benefits made easy</h1>
        <div className={classNames.desc}>
          We help Indian <IndianFlag /> companies create
          <div>their own automated employee benefits system</div>
        </div>
        <div className={classNames.btnsContainer}>
          <button>Earn With CoverFi</button>
          <button>Request Demo</button>
        </div>
        <div className={classNames.imageContainer}>
          <div>
            <img src={women} alt="women" />
            <div>
              <img src={family4} alt="family4" />
              <img src={family3} alt="family3" />
            </div>
          </div>
          <div>
            <img src={couple} alt="couple" />
          </div>
          <div>
            <div>
              <img src={singh} alt="singh" />
              <img src={family4_2} alt="family4_2" />
            </div>
            <img src={lawyer} alt="lawyer" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
